<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            <app-i18n code="user.edit.title" v-if="isEditing"></app-i18n>
            <app-i18n code="user.new.title" v-else></app-i18n>
          </h2>
          <section class="relative">
            <div
              style="width: 100%; height: 80vh"
              class="flex justify-center"
              v-if="findLoading"
            >
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div>
            <div v-if="model">
              <div class="grid grid-cols-12 mt-9">
                <h4
                  class="flex text-lg font-medium leading-none items-center mr-2 lg:col-span-2 col-span-12"
                >
                  {{ i18n('iam.fields.fullName') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="crud-form-1"
                    type="text"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.fullName"
                    :placeholder="i18n('iam.placeholder.fullName')"
                    required
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-9">
                <h4
                  class="flex text-lg font-medium leading-none items-center mr-2 lg:col-span-2 col-span-12"
                >
                  {{ i18n('iam.fields.phoneNumber') }}
                </h4>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  style="dir: ltr"
                >
                  <vue-tel-input
                    v-model="model.phoneNumber"
                    mode="international"
                    validCharactersOnly
                    :inputOptions="isRTL ? optionsAr : options"
                    :dropdownOptions="telDropOptions"
                  ></vue-tel-input>
                </div>
              </div>
              <div class="flex justify-center items-center mt-10">
                <AppButton
                  type="button"
                  class="btn bg-theme-31 text-white cursor-pointer"
                  :class="!saveLoading ? 'w-24' : ''"
                  :disabled="saveLoading || findLoading"
                  :loading="saveLoading"
                  @click="doSubmit"
                >
                  <strong>{{ i18n('common.save') }}</strong>
                  <template v-slot:loading>
                    <app-i18n code="common.loading"></app-i18n>
                    <LoadingIcon
                      icon="three-dots"
                      color="#FFFFFF"
                      style="margin: 0 4px"
                    />
                  </template>
                </AppButton>
                <button
                  type="button"
                  class="btn bg-theme-32 text-black w-24 ml-3 mr-3 cursor-pointer"
                  @click="doCancel()"
                >
                  <app-i18n code="common.cancel"></app-i18n>
                </button>
              </div>
            </div>
          </section>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'
import { FormSchema } from '@/shared/form/form-schema'
import { UserModel } from '@/store/user/user-model'

const { fields } = UserModel
const formSchema = new FormSchema([
  fields.id,
  fields.fullName,
  fields.phoneNumber,
  fields.lang
])

export default {
  props: {
    id: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      image: null,
      imageUrl: '',
      errorMessage: '',
      uploadLoading: false,
      model: null,
      rules: formSchema.rules(),
      options: { placeholder: 'Enter Your Phone Number', showDialCode: true },
      optionsAr: { placeholder: 'ادخل رقم تليفونك', showDialCode: true },
      telDropOptions: {
        showFlags: true
      }
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'user/form/record',
      findLoading: 'user/form/findLoading',
      saveLoading: 'user/form/saveLoading'
    }),
    isEditing() {
      return !!this.id
    }
  },
  created() {
    document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.users')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.users')
    }
  },
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id)
    } else {
      this.doNew()
    }
    this.model = formSchema.initialValues(this.record || {})
  },
  methods: {
    ...mapActions({
      doNew: 'user/form/doNew',
      doFind: 'user/form/doFind',
      doCreate: 'user/form/doAdd',
      doUpdate: 'user/form/doUpdate'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    isFormValid() {
      if (this.model.fullName && this.model.fullName.trim().length === 0) {
        this.errorMessage = 'notifications.create.popup.emptyFields'
        return false
      }
      if (this.model.phoneNumber && this.model.phoneNumber.trim().length < 5) {
        this.errorMessage = 'notifications.create.popup.emptyFields'
        return false
      }
      return true
    },
    doCancel() {
      this.$router.back()
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }
      this.model.lang = this.language
      const { id, ...values } = formSchema.cast(this.model)
      if (this.isEditing) {
        await this.doUpdate({
          id,
          values: {
            ...values,
            phoneNumber: values.phoneNumber.replaceAll(' ', '')
          }
        })
      } else {
        await this.doCreate({
          ...values,
          phoneNumber: values.phoneNumber.replaceAll(' ', '')
        })
      }
    }
  }
}
</script>
